<template>
  <v-row>
    <v-col cols="12">
      <v-btn link text color="secondary" @click="goBack">
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Back
      </v-btn>
    </v-col>
    <template>
    <v-col cols="12">
      <h1 class="my-5">History</h1>
      <date-time-picker :dates="filterDates"  />
      <div class="glass-block" >
            <Table
                :headers="this.$constants.headers"
                :items="pastStatusData"
                :pagination="1"
                :exportOption=true
                :footer-props="this.$constants.footerProps"
                @showIncident="showIncident"
                @showMaintenance="showMaintenance">
            </Table>
      </div>
    </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'History',
  components: {
    Table: () => import('../components/interface/table'),
    dateTimePicker: () => import('../components/interface/date-time-picker')
  },
  computed: {
    ...mapGetters('Site', ['getPastStatus', 'getFilterDates']),

    filterDates(){
      return this.getFilterDates
    },
    pastStatusData () {
      if (this.filterDates.length) {
        return this.pastStatus.filter((item) => {
          const startDate = this.$moment.unix(item.start_date).utc().format('YYYY-MM-DD')
          return startDate >= this.filterDates[0] && startDate <= this.filterDates[1]
        })
      } else {
        return this.pastStatus
      }
    },
    pastStatus(){
      return this.getPastStatus
    },
    siteId () {
      return this.$route.params.site || null
    },

  },

  methods: {
    showIncident (incident) {
      this.$router.push(`/history/${this.siteId}/incident/${incident.id}`)
    },
    showMaintenance (maintenance) {
      this.$router.push(`/history/${this.siteId}/maintenance/${maintenance.id}`)
    },
    goBack(){
      this.$router.push(`/dashboard/${this.siteId}`)
    },

  }
}
</script>

<style scoped lang="scss">

</style>
