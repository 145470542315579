<template>
  <v-row class="pa-md-4 pa-2">
    <v-col cols="12">
      <v-btn link text color="secondary" @click="goBack">
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Back
      </v-btn>
    </v-col>
    <template>
    <v-col cols="12">
      <h1 class="my-5">Current Status</h1>
      <div class="glass-block" >
            <Table
                :headers="this.$constants.headers"
                :items="currentStatus"
                :pagination="1"
                :footer-props="this.$constants.footerProps"
                @showIncident="showIncident"
                @showMaintenance="showMaintenance"
            />
      </div>
    </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'current_status',
  components: {
    Table: () => import('../components/interface/table'),
  },

  computed: {
    ...mapGetters('Site', [ 'getOngoingMaintenance', 'getRealTimeIncidents']),

    siteId () {
      return this.$route.params.site || null
    },
    currentStatus(){
      return [...this.getOngoingMaintenance, ...this.getRealTimeIncidents]
    },


  },

  methods: {
    showIncident (incident) {
      this.$router.push(`/current/${this.siteId}/incident/${incident.id}`)
    },
    showMaintenance (maintenance) {
      this.$router.push(`/current/${this.siteId}/maintenance/${maintenance.id}`)
    },
    goBack(){
      this.$router.push(`/dashboard/${this.siteId}`)
    },

  }
}
</script>

<style scoped lang="scss">

</style>
